// .company ready
import {
    Link,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import ContactInfo from './ContactInfo'
import { useStore } from '../../common/Context'
import useAuth from '../../common/hooks/useAuth'
import useToast from '../../common/hooks/useToast'
import useModal from '../../common/hooks/useModal'
import ConfirmModalContent from '../../components/common/ConfirmModalContent'
import useError from '../../common/hooks/useError'
import fetchAPI from '../../common/fetchAPI'
import ActionsDropdown from '../../components/common/ActionsDropdown'
import { ICONS } from '../../common/constants'
import Toggle from '../../components/common/Toggle'
import BasicInfo from './BasicInfo'
import MembershipInfo from './MembershipInfo'
import AccountInfo from './AccountInfo'
import BillingMethodInfo from './BillingMethodInfo'
import BillingData from './BillingData'
import Notes from './Notes'
import AccessInfo from './AccessInfo'
import UserTasks from './UserTasks'
import useData from '../../common/hooks/useData'
import TeamInfo from './TeamInfo'
import { AssignedUsersToggle } from './AssignedUsersToggle'
import Timesheets from './Timesheets'
import { getUserDisplayName } from '../../common/helpers'
import MessageSection from '../../components/common/MessageSection'
import authStorage from '../../common/authStorage'
import Spinner from '../../components/common/Spinner'
import ErrorMessage from '../../components/common/ErrorMessage'
import Tag from '../../components/common/Tag'
import Button from '../../components/common/Button'
import MainButton from '../../components/admin/MainButton'
import BlockStack from '../../components/common/BlockStack'
import InlineStack from '../../components/common/InlineStack'
import InlineStackItem from '../../components/common/InlineStackItem'
import FavoriteInfo from './FavoriteInfo'
import SectionContainer from '../../components/common/SectionContainer'
import Divider from '../../components/common/Divider'
import Break from '../../components/common/Break'
import Chat from '../../components/common/Chat'

const permissions = {
    user: {
        admin: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,jobType,teamRoles,defaultHourlyRate,assignedClientIds,labels,billingIds,isPremiumUser,role,notes,externalNotes,internalNotes,lastAccountAccessDate,lastPasswordResetDate,accounts',
            populate: 'assignedClients,contactDetails,billingMethods',
        },
        assistant: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,jobType,teamRoles,defaultHourlyRate,assignedClientIds,labels,billingIds,isPremiumUser,role,notes,externalNotes,internalNotes,lastAccountAccessDate,lastPasswordResetDate,accounts',
            populate: 'assignedClients,contactDetails,billingMethods',
        },
        sales: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,labels,billingIds,isPremiumUser,role,notes,externalNotes,internalNotes,accounts',
            populate: 'contactDetails,billingMethods',
        },
        collections: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,labels,billingIds,isPremiumUser,role,notes,externalNotes,internalNotes,accounts',
            populate: 'contactDetails,billingMethods',
        },
        staff: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,labels,isPremiumUser,role,notes,externalNotes,internalNotes,accounts,teamRoles',
        },
    },
    contact: {
        all: {
            fields: 'firstName,lastName,email,notes',
            populate: 'contactDetails',
        },
    },
}

function getFieldPermissions(auth, isContact) {
    let fields
    let populate
    if (isContact) {
        fields = permissions.contact.all.fields
        populate = permissions.contact.all.populate
    } else if (auth.isAdmin) {
        fields = permissions.user.admin.fields
        populate = permissions.user.admin.populate
    } else if (auth.isAssistant) {
        fields = permissions.user.assistant.fields
        populate = permissions.user.assistant.populate
    } else if (auth.isSales) {
        fields = permissions.user.sales.fields
        populate = permissions.user.sales.populate
    } else if (auth.isCollections) {
        fields = permissions.user.collections.fields
        populate = permissions.user.collections.populate
    } else if (auth.isStaff) {
        fields = permissions.user.staff.fields
        populate = permissions.user.staff.populate
    }
    return { fields, populate }
}

export default function ProfilePage() {
    const [_state, dispatch] = useStore()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const { contactId, userId } = useParams()
    const [searchParams] = useSearchParams()
    const tagFromParams = searchParams.get('tag_task_list')

    const isOwnProfile = !contactId && !userId
    const isContact = Boolean(contactId)

    let { fields, populate } = getFieldPermissions(auth, isContact)

    let url = ''
    if (isContact) {
        url = `/v1/contacts/${contactId}?fields=${fields}${
            populate ? `&populate=${populate}` : ''
        }`
    } else if (isOwnProfile) {
        url = `/v1/users/${auth.user.id}?fields=email,firstName,lastName,isPremiumUser,accounts,publicProfileUrl,role,teamRoles&populate=contactDetails,billingMethods`
    } else {
        url = `/v1/users/${userId}?fields=${fields}${
            populate ? `&populate=${populate}` : ''
        }`
    }
    const { user, userError, userLoading, userMutate } = useData(url, 'user')

    const {
        profileCompletion,
        profileCompletionError,
        profileCompletionValidating,
        profileCompletionLoading,
    } = useData(
        auth.isClient && isOwnProfile
            ? '/v1/users/me/profile-completion'
            : null,
        'profileCompletion',
    )

    const [error, setError] = useError()
    const navigate = useNavigate()

    async function onConvertToUser() {
        setError('')
        setModal(
            <ConfirmModalContent
                text={
                    'Converting to an app user will not send an email. You can do this via client Settings. If the contact has a company, one will be created or edited to include them as a member.'
                }
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { responseData, error } = await fetchAPI(
                        `/v1/contacts/${user.id}/convert-to-user`,
                        {},
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(`/users/${responseData.userId}`)
                }}
            />,
            'Convert to app user?',
        )
    }
    async function onHoldChange() {
        setError('')
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const actionUrl = user.onHold
                        ? `/v1/payment/clients/${user.id}/revert-on-hold`
                        : `/v1/payment/clients/${user.id}/on-hold`

                    const { responseData, error } = await fetchAPI(
                        actionUrl,
                        {},
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    userMutate({ ...user, ...responseData })
                }}
            />,
            "Are you sure you want to change this user's on-hold status?",
        )
    }

    async function onBlackListChange() {
        setError('')
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const actionUrl = user.onBlackList
                        ? `/v1/payment/clients/${user.id}/revert-on-blacklist`
                        : `/v1/payment/clients/${user.id}/on-blacklist`

                    const { responseData, error } = await fetchAPI(
                        actionUrl,
                        {},
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    userMutate({ ...user, ...responseData })
                }}
            />,
            'Are you sure you want to change this user’s blacklist status?',
        )
    }

    async function onContactBlackListChange() {
        const { responseData, error } = await fetchAPI(
            `/v1/contacts/${contactId}`,
            {
                onBlackList: !user.onBlackList,
            },
            'PATCH',
        )
        if (error) return setError(error)
        userMutate({ ...user, ...responseData })
        setToast('Done')
    }

    async function onCollectionsChange() {
        setError('')
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const actionUrl = user.onCollections
                        ? `/v1/payment/clients/${user.id}/revert-on-collections`
                        : `/v1/payment/clients/${user.id}/on-collections`

                    const { responseData, error } = await fetchAPI(
                        actionUrl,
                        {},
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    userMutate({ ...user, ...responseData })
                }}
            />,
            'Are you sure you want to change this user’s collections status?',
        )
    }
    async function onToggleBlock() {
        const { responseData, error } = await fetchAPI(
            `/v1/users/${user.id}`,
            {
                isBlocked: !user.isBlocked,
            },
            'PATCH',
        )
        if (error) return setError(error)
        userMutate({ ...user, ...responseData })
        setToast('Done')
    }

    async function onLoginAsUser() {
        const { responseData, error } = await fetchAPI(
            `/v1/auth/login-as-user`,
            {
                userId: user.id,
            },
            'POST',
        )

        if (error) return setError(error)

        const { user: responseUser, tokens } = responseData
        authStorage.setItem('access_token', tokens.access.token)
        authStorage.setItem('refresh_token', tokens.refresh.token)

        dispatch({ type: 'LOGIN', payload: responseUser })
        navigate('/')
        window.location.reload()
    }

    function onChat() {
        setModal(
            <Chat roomType={'direct'} otherUserIds={[user.id]} />,
            '',
            'modal-full',
        )
    }

    if (userLoading) {
        return <Spinner />
    }
    if (userError) {
        return <ErrorMessage section>{userError}</ErrorMessage>
    }

    if (isOwnProfile && !user) {
        return <ErrorMessage section>Unauthenticated</ErrorMessage>
    }

    let editUrl
    if (isContact) {
        editUrl = `/contacts/${user.id}/profile/edit?type=contact`
    } else if (user.role === 'staff') {
        editUrl = `/contacts/${user.id}/profile/edit?type=staff`
    } else {
        editUrl = `/contacts/${user.id}/profile/edit?type=user`
    }

    const dropdownActions = [
        {
            title: 'Edit',
            onClick: () => navigate(editUrl),
            hide: !auth.isAdmin && !auth.isAssistant,
            icon: ICONS.EDIT_GRAY,
        },
        {
            title: 'Chat',
            onClick: () => onChat(),
            hide: isContact,
            icon: ICONS.MESSAGE_SQUARE_GRAY,
        },
        {
            title: user.onHold ? 'Clear hold' : 'Place on hold',
            onClick: () => onHoldChange(),
            hide: !auth.isAdmin || isContact,
        },
        {
            title: user.onBlackList
                ? 'Remove from blacklist'
                : 'Add to blacklist',
            onClick: () => onBlackListChange(),
            hide: (!auth.isAdmin && !auth.isClientManager) || isContact,
        },
        {
            title: user.onCollections
                ? 'Remove from collections'
                : 'Add to collections',
            onClick: () => onCollectionsChange(),
            hide: (!auth.isAdmin && !auth.isClientManager) || isContact,
        },
        {
            title: user.isBlocked ? 'Unblock' : 'Block',
            onClick: () => onToggleBlock(),
            hide: (!auth.isAdmin && !auth.isAssistant) || isContact,
            icon: ICONS.SLASH_RED,
            type: 'alert',
        },
        {
            title: 'Settings',
            onClick: () => navigate(`/users/${userId}/settings`),
            hide: (!auth.isAdmin && !auth.isAssistant) || isContact,
            icon: ICONS.SETTINGS_GRAY,
        },
        {
            title: 'Login as this user',
            onClick: () => onLoginAsUser(),
            hide: (!auth.isAdmin && !auth.isClientManager) || isContact,
            icon: ICONS.LOGIN_GRAY,
        },
        {
            title: 'Metafields',
            onClick: () =>
                navigate(
                    `/${contactId ? 'contacts' : 'users'}/${
                        contactId || userId
                    }/metafields`,
                ),
            hide: !auth.isAdmin && !isContact,
        },
    ]

    const canSeeCollectionsInfo = auth.isAdmin || auth.isCollections
    const canSeeContactInfo =
        isOwnProfile ||
        auth.isAdmin ||
        auth.isAssistant ||
        auth.isSales ||
        Boolean(contactId)

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </SectionContainer>
            )}
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack gap={'sm'} spaceBetween>
                        <BlockStack gap={'tiny'}>
                            <InlineStack itemsCenter>
                                <InlineStackItem>
                                    <h2>{getUserDisplayName(user)}</h2>
                                </InlineStackItem>

                                <FavoriteInfo
                                    type={isContact ? 'Lead' : 'User'}
                                    id={userId || contactId}
                                />
                            </InlineStack>
                            <InlineStack gap={'sm'} wrap>
                                {user.email && (
                                    <small className="text-secondary">
                                        <Button
                                            icon={ICONS.MAIL_GRAY}
                                            link
                                            plain
                                            inline
                                            className={'a'}
                                            tiny
                                            href={`mailto:${user.email}`}
                                            text={user.email}
                                        />
                                    </small>
                                )}
                                {user.contactDetails?.phone && (
                                    <small className="text-secondary">
                                        <Button
                                            icon={ICONS.PHONE_GRAY}
                                            link
                                            plain
                                            inline
                                            className={'a'}
                                            tiny
                                            href={`tel:${user.contactDetails.phone}`}
                                            text={user.contactDetails.phone}
                                        />
                                    </small>
                                )}
                            </InlineStack>
                            {user.isPremiumUser && (
                                <div>
                                    <Tag color="gold" outline>
                                        Premium
                                    </Tag>
                                </div>
                            )}
                            {canSeeCollectionsInfo &&
                                (user.onHold ||
                                    user.onBlackList ||
                                    user.isBlocked ||
                                    user.onCollections) && (
                                    <InlineStack gap="sm">
                                        {user.onHold ? (
                                            <Tag noMargin color="alert">
                                                Hold
                                            </Tag>
                                        ) : null}
                                        {user?.onBlackList ? (
                                            <Tag noMargin color="alert">
                                                Blacklist
                                            </Tag>
                                        ) : null}
                                        {user.isBlocked ? (
                                            <Tag noMargin color="alert">
                                                Blocked
                                            </Tag>
                                        ) : null}
                                        {user.onCollections ? (
                                            <Tag noMargin color="alert">
                                                Collections
                                            </Tag>
                                        ) : null}
                                    </InlineStack>
                                )}

                            {(user.contactType === 'friend' ||
                                user.contactType === 'family') && (
                                <InlineStack gap="sm">
                                    {user.contactType === 'friend' && (
                                        <Tag noMargin color={'primary'}>
                                            Friend
                                        </Tag>
                                    )}
                                    {user.contactType === 'family' && (
                                        <Tag noMargin color={'primary'}>
                                            Family
                                        </Tag>
                                    )}
                                </InlineStack>
                            )}
                        </BlockStack>

                        {!auth.isClient && !isContact && (
                            <ActionsDropdown actions={dropdownActions} />
                        )}
                        {auth.isAdmin && isContact && (
                            <ActionsDropdown
                                actions={[
                                    {
                                        title: 'Edit',
                                        onClick: () => navigate(editUrl),
                                        hide:
                                            !auth.isAdmin && !auth.isAssistant,
                                        icon: ICONS.EDIT_GRAY,
                                    },
                                    {
                                        title: 'Convert to user',
                                        onClick: () => onConvertToUser(),
                                        hide: !auth.isAdmin || !isContact,
                                    },
                                    {
                                        title: user.onBlackList
                                            ? 'Remove from blacklist'
                                            : 'Add to blacklist',
                                        onClick: () =>
                                            onContactBlackListChange(),
                                    },
                                ]}
                            />
                        )}
                    </InlineStack>
                </header>
            </SectionContainer>
            <Divider />
            {auth.isClient && isOwnProfile && profileCompletionError ? (
                <SectionContainer>
                    <ErrorMessage>
                        There is an issue with loading profile information,
                        please contact Support.
                    </ErrorMessage>
                </SectionContainer>
            ) : null}
            {auth.isClient &&
                isOwnProfile &&
                // auth.user.todo?.vendorAccount &&
                !profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgProductsQuestions ===
                    false && (
                    <SectionContainer>
                        <MessageSection
                            title="Please fill in some info about your products and your brand"
                            type="info"
                            fullWidthButton
                            small
                        >
                            <Button
                                small
                                fullWidth
                                outline
                                white
                                href={`/profile/products-questions/edit?organizationId=${profileCompletion?.completedCompanyOrgProductsQuestionsMissingOrgIds?.[0]}`}
                                text="Go to form"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}
            {isOwnProfile && auth.isClient && !auth.isPremiumUser ? (
                <SectionContainer>
                    <MessageSection
                        title="Upgrade to Premium"
                        type="info"
                        fullWidthButton
                        small
                    >
                        <Button
                            small
                            fullWidth
                            outline
                            white
                            href={`/profile/billing/purchase-premium`}
                            text="Upgrade now"
                        />
                    </MessageSection>
                </SectionContainer>
            ) : null}
            {user.role === 'staff' && !auth.isClient && (
                <SectionContainer>
                    <Toggle
                        open={!auth.isAdmin}
                        title="Team"
                        small
                        heading={'h2'}
                    >
                        <TeamInfo
                            jobType={user.jobType}
                            teamRoles={user.teamRoles}
                            defaultHourlyRate={user.defaultHourlyRate}
                        />
                    </Toggle>
                </SectionContainer>
            )}
            {user.role === 'staff' && (auth.isAdmin || auth.isAssistant) && (
                <SectionContainer>
                    <AssignedUsersToggle
                        userId={user.id}
                        assignedClientIds={user.assignedClientIds}
                        assignedClients={user.assignedClients}
                    />
                </SectionContainer>
            )}
            <SectionContainer>
                <Toggle
                    open={!auth.isAdmin}
                    title="Contact information"
                    small
                    heading={'h2'}
                >
                    <BasicInfo
                        userId={userId}
                        contactDetails={user.contactDetails}
                        labels={user.labels}
                        contactId={contactId}
                        canSeeContactInfo={canSeeContactInfo}
                    />
                </Toggle>
            </SectionContainer>
            {user.role === 'user' && (auth.isAdmin || isOwnProfile) && (
                <SectionContainer>
                    <Toggle
                        title="Membership"
                        small
                        heading={'h2'}
                        gold={user.isPremiumUser}
                    >
                        {user.isPremiumUser ? 'Premium' : 'Free'}
                        <Break />

                        {isOwnProfile ? (
                            <>
                                <Link to="/profile/billing/premium">
                                    See subscription details →
                                </Link>
                            </>
                        ) : (
                            <MembershipInfo
                                billingIds={user.billingIds}
                                isPremiumUser={user.isPremiumUser}
                            />
                        )}
                        <Break />
                        {isOwnProfile && (
                            <div>
                                <Link to="/donate">Donations →</Link>
                            </div>
                        )}
                    </Toggle>
                </SectionContainer>
            )}
            {user.role === 'user' &&
                (isOwnProfile || auth.isAdmin || auth.isAssistant) && (
                    <SectionContainer>
                        <Toggle
                            title="Accounts"
                            open={!auth.isAdmin}
                            small
                            heading={'h2'}
                        >
                            <AccountInfo userId={userId} />
                        </Toggle>
                    </SectionContainer>
                )}
            {user.role === 'user' &&
                (isOwnProfile || auth.isAdmin || auth.isAssistant) && (
                    <SectionContainer>
                        <Toggle title="Billing methods" small heading={'h2'}>
                            <BillingMethodInfo
                                billingMethods={user.billingMethods}
                            />
                        </Toggle>
                    </SectionContainer>
                )}
            {user.role === 'user' && (auth.isAdmin || auth.isCollections) && (
                <SectionContainer>
                    <Toggle title="Billing" small heading={'h2'}>
                        <BillingData />
                    </Toggle>
                </SectionContainer>
            )}
            {(auth.isAdmin || auth.isStaff) && !isContact && (
                <SectionContainer>
                    <Toggle
                        title="Tasks"
                        small
                        heading={'h2'}
                        open={tagFromParams || undefined}
                    >
                        <UserTasks userId={user.id} role={user.role} />
                    </Toggle>
                    <MainButton
                        functionality={'CREATE'}
                        label="Add task"
                        href={`/users/${user.id}/tasks/new`}
                    />
                </SectionContainer>
            )}
            {user.role === 'staff' && (auth.isAdmin || auth.isAssistant) && (
                <SectionContainer>
                    <Toggle
                        open={!auth.isAdmin}
                        title="Timesheets"
                        small
                        heading={'h2'}
                    >
                        <Timesheets userId={user.id} />
                    </Toggle>
                </SectionContainer>
            )}
            {!(auth.isClient && isOwnProfile) && (
                <SectionContainer>
                    <Toggle title="Notes" small heading={'h2'}>
                        <Notes
                            id={user.id}
                            role={user.role}
                            notes={user.notes}
                            externalNotes={user.externalNotes}
                            internalNotes={user.internalNotes}
                        />
                    </Toggle>
                </SectionContainer>
            )}
            {(auth.isAdmin || auth.isAssistant || auth.isClientManager) &&
                !isContact && (
                    <SectionContainer>
                        <Toggle title="Access" small heading={'h2'}>
                            <AccessInfo
                                lastAccountAccessDate={
                                    user.lastAccountAccessDate
                                }
                                lastPasswordResetDate={
                                    user.lastPasswordResetDate
                                }
                            />
                        </Toggle>
                    </SectionContainer>
                )}
        </>
    )
}
