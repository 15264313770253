import { useState } from 'react'
import Input from './data-form/Input'
import useData from '../../common/hooks/useData'
import PrivateFile from './PrivateFile'
import Grid from './Grid'
import BlockStack from './BlockStack'
import ScrollableContent from './ScrollableContent'
import CheckboxInput from './data-form/CheckboxInput'
import Button from './Button'
import ErrorMessage from './ErrorMessage'
import Spinner from './Spinner'
import fetchAPI from '../../common/fetchAPI'
import useToast from '../../common/hooks/useToast'

const getImageDimensions = (file) =>
    new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = function (e) {
            const img = new Image()
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                })
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(file)
    })

function FilePickerModal({
    onClose,
    onConfirm,
    maxFiles,
    isPublic,
    confirmLabel,
}) {
    const [selectedFileIds, setSelectedFileIds] = useState([])
    const [uploadLoading, setUploadLoading] = useState(null)
    const [inputKey, setInputKey] = useState(Date.now())
    const setToast = useToast()
    const { files, filesError, filesLoading, filesMutate, filesValidating } =
        useData(
            `/v1/files/list/me`,
            'files',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
        )

    function handleCheckboxChange(id) {
        setSelectedFileIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((i) => i !== id)
            } else {
                if (prev.length >= maxFiles) {
                    return [...prev.slice(1), id]
                } else {
                    return [...prev, id]
                }
            }
        })
    }

    async function onUpload(files) {
        if (!files?.length) {
            return
        }

        setUploadLoading(true)

        const newFiles = []

        for (const file of files) {
            const { width, height } = await getImageDimensions(file)

            setToast('Upload started')

            const formData = new FormData()
            formData.append(`file`, file)
            formData.append(
                `data`,
                JSON.stringify({
                    properties: {
                        width: width,
                        height: height,
                    },
                    isPublic,
                }),
            )

            const { responseData, error } = await fetchAPI(
                `/v1/files/list/me`,
                formData,
                'POST',
                {},
            )
            if (error) {
                setToast(error, 'alert')
                return
            }
            newFiles.push(responseData)
        }

        filesMutate([...(files || []), ...newFiles])
        setInputKey(Date.now())
        setUploadLoading(false)
        setToast('Uploaded successfully')
    }

    function handleClose() {
        onClose()
    }

    function handleConfirm() {
        onConfirm(files.filter(({ id }) => selectedFileIds.includes(id)))
    }

    return (
        <div className="modal file-picker-modal">
            <div className={`modal-container show`}>
                <div className="modal-header">
                    <h4>Pick a file</h4>

                    <button className="modal-close-btn" onClick={handleClose}>
                        &#10005;
                    </button>
                </div>
                <div className="modal-content">
                    <Input
                        key={inputKey}
                        label={'Upload a file'}
                        type="file"
                        id="form-attachment-browser"
                        multiple
                        onChange={(files) => onUpload(files)}
                    />

                    {/* <Break /> */}
                    <ScrollableContent>
                        {Boolean(filesError) && (
                            <ErrorMessage>{filesError}</ErrorMessage>
                        )}
                        {(filesLoading || filesValidating || uploadLoading) && (
                            <Spinner marginBottom />
                        )}

                        <Grid cols={2}>
                            {(files || []).map(({ id, name }) => (
                                <BlockStack gap={'tiny'} key={id}>
                                    <CheckboxInput
                                        label={name}
                                        hideLabel
                                        noBr
                                        value={selectedFileIds.includes(id)}
                                        onChange={() =>
                                            handleCheckboxChange(id)
                                        }
                                    />
                                    <div
                                        onClick={() => handleCheckboxChange(id)}
                                    >
                                        <PrivateFile driveFileId={id} />
                                    </div>
                                    <small className="text-subdued">
                                        {name}
                                    </small>
                                </BlockStack>
                            ))}
                        </Grid>
                    </ScrollableContent>

                    {Boolean(selectedFileIds.length) && (
                        <Button
                            text={confirmLabel || 'Select'}
                            onClick={handleConfirm}
                        />
                    )}
                </div>
            </div>
            <div className="modal-overlay"></div>
        </div>
    )
}

export default function useFilePicker() {
    const [pickerHtml, setPickerHtml] = useState(false)

    function openPicker({ maxFiles, isPublic, confirmLabel } = {}) {
        return new Promise(function (resolve) {
            const pickerHtml = (
                <FilePickerModal
                    confirmLabel={confirmLabel}
                    isPublic={isPublic}
                    maxFiles={maxFiles}
                    onClose={() => {
                        resolve()
                        setPickerHtml(null)
                    }}
                    onConfirm={(results) => {
                        resolve(results)
                        setPickerHtml(null)
                    }}
                />
            )

            setPickerHtml(pickerHtml)
        })
    }

    return {
        openPicker,
        pickerHtml,
    }
}
